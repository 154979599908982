export const TOKEN = 'access';
export const REFRESH = 'refresh';
export const PREFIX = 'PlannSat';

export const HELP_URLS = {
    'global': 'https://scribehow.com/page/GUIA_DE_AYUDA_PLANNSAT__6kNlPoaxS3ea1Ziqav0BhA',
    'paises': "https://scribehow.com/shared/PAISES__e-2vjnbEScyyR0xOiNyjpw",
    'provincias': "https://scribehow.com/shared/PROVINCIAS__7ayuvnSmQKOF2OAQ6goU9w",
    'ciudades': "https://scribehow.com/shared/CIUDADES__u53BsnudT9iWMyiq6BXrYA",
    'tipos_intervencion': "https://scribehow.com/shared/TIPOS_DE_INTERVENCION__TZvO1Od3RUGYAay-cqPLzw",
    'modo_pago': "https://scribehow.com/shared/MODOS_DE_PAGO__uNa_I7dhSAGKPHaNoSxbJw",
    'periodicidades': "https://scribehow.com/shared/PERIODICIDADES__DiSl7lC7T5iQ3MWeOhZpdg",
    'tipos_equipo': "https://scribehow.com/shared/TIPOS_DE_EQUIPO__hL6d2WilQSabhdjfVPQRsQ",
    'equipos': "https://scribehow.com/shared/EQUIPOS__G-VgdgyMSq6Da-lA8dplHg",
    'clientes': 'https://scribehow.com/shared/CLIENTES__EPy9HjTISgi0VGfc3gap9w',
    'categoria_operario': 'https://scribehow.com/shared/CATEGORIAS_OPERARIOS__lY5vUmXCQ6quXaogj3O4lQ',
    'proveedores_externos': 'https://scribehow.com/shared/PROVEEDORES_EXTERNOS__erwM-3wcQXmnczMedD9RBQ',
    'operarios': 'https://scribehow.com/shared/OPERARIOS__FSPupdW1Rhak9_JqULyUoA',
    'usuarios': 'https://scribehow.com/shared/USUARIOS__Od5qTDXTTyW03AGZ87EnJA',
    'intervencion': 'https://scribehow.com/shared/INTERVENCION_SIMPLE__gOFhEVGsQXCje-OHjmRjpA',
}
