import { createContext } from 'react';

export const HelpContext = createContext(
    {} as {
        open: (
            title: string,
            url: string,
            hasClose?: boolean,
            size?: string
        ) => void;
        close: () => void;
    }
);
export const HelpProvider = HelpContext.Provider;
