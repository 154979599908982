import {useEffect, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';

import {Input} from '../../elements/input';
import {useTranslation} from 'react-i18next';
import {useHttpClient} from '../../shared/http-client';
import {Select} from '../../elements/select';
import {FormFooter} from '../../elements/form-footer';
import {FieldError} from '../../elements/field-error/field-error';
import {DateTimePicker} from '../../elements/date-time-picker';
import dayjs from 'dayjs';

const validations = Yup.object().shape({});

const initialValues = {};

export const MapsFilterForm = ({
                                   item, close, doFilter, doSubmit
                               }: any) => {
    const {t} = useTranslation();
    const {api, state} = useHttpClient();
    const [data, setData] = useState<any | null>({});
    const [operarios, setOperarios] = useState<any | null>([]);


    useEffect(() => {
        api(`/operarios/?is_active=true`, 'GET');
    }, []);

    useEffect(() => {
        if (item) {
            setData(item);
        }
    }, [item]);
    
    useEffect(() => {
        if (state.data) {
            if (state.path.includes('operarios')) {
                setOperarios(state.data.results);
            }
        }

        setData({...data, ...initialValues});
    }, [state]);

    const onSubmit = async (values: any | null) => {
        doFilter(values);
    };

    return (<div>
        <div className="flex flex-col mb-4">
            <div className={'font-bold text-xl'}>{data?.name}</div>
        </div>

        <Formik
            enableReinitialize={true}
            initialValues={data}
            validationSchema={validations}
            validateOnBlur={true}
            onSubmit={onSubmit}
        >
            {({
                  values, errors, touched, setFieldValue, handleSubmit,
              }) => (<form onSubmit={handleSubmit} autoComplete="off">
                <Select
                    value={values.operario ?? {}}
                    label={t('intervencion.form.operario')}
                    placeholder="common.placeholder.select-value"
                    items={operarios}
                    name="operario"
                    onChange={(value) => setFieldValue('operario', value.operario)}
                />

                <FormFooter
                    item={item}
                    doDelete={() => doFilter({})}
                    doSubmit={handleSubmit}
                    close={close}
                />
            </form>)}
        </Formik>
    </div>);
};
