    import {useState} from 'react';
import {useDrawer} from '../../shared/drawer';
import {PageBody} from '../../elements/page-body';
import {TiposNotificacionForm} from './tipos-notificacion-form';
import {Table} from '../../elements/table';

export const TiposNotificacion = () => {
    const {open, close} = useDrawer();
    const [refresh, setRefresh] = useState<string | null>(null);

    const handleNewAction = () => {
        open(
            'tipos-notificacion.form.new-item',
            <TiposNotificacionForm close={handleClose}/>
        );
    };

    const handleClose = () => {
        setRefresh(Date.now().toString());
        close();
    };

    return (
        <PageBody
            title={'tipos-notificacion.text.title'}
            newActionTitle={'tipos-notificacion.button.new'}
            newAction={handleNewAction}
        >
            <Table
                path="/tipos-notificacion"
                refresh={refresh}
                callBeforeDrawerClosed={handleClose}
                onDelete={handleClose}
                Form={TiposNotificacionForm}
                withPagination
                headers={[
                    {
                        key: 'name',
                        label: 'common.form-label.name'
                    },
                ]}
            />
        </PageBody>
    );
};
