import {PageBody} from '../../elements/page-body';
import {NotificacionesComponent} from './notificaciones.component';
import {useDrawer} from '../../shared/drawer';
import {NotificacionesFilter} from './notificaciones-filter';
import {useEffect, useState} from 'react';
import {NotificacionForm} from "./notificacion-form";
import {CogIcon} from "@heroicons/react/outline";

const INITIAL_FILTERS = {
    // fecha_after: dayjs().format('YYYY-MM-DD'),
    // fecha_before: dayjs().add(1, 'days').format('YYYY-MM-DD')
    done: false
}

export const NotificacionesList = () => {
        const {open, close} = useDrawer();
        const [filters, setFilters] = useState<any>({});

        useEffect(() => {
            setFilters(INITIAL_FILTERS);
        }, []);

        const handleClose = () => {
            close();
            window.location.reload();
        };

        const handleFilters = () => {
            open(
                'notificaciones.filter.title',
                <NotificacionesFilter close={close} doFilter={doFilter} item={filters}/>,
                true,
                '2xl'
            );
        };

        const doFilter = (filters: any) => {
            close();
            if (Object.keys(filters).length > 0) {
                setFilters(filters);
            } else {
                setFilters(INITIAL_FILTERS);
            }
        }

        const handleNewAction = () => {
            open(
                'notificaciones.form.new-item',
                <NotificacionForm close={handleClose} cancel={() => close()}/>
            );
        };


        return (
            <PageBody
                title={'notificaciones.text.title'}
                filterAction={handleFilters}
                newAction={handleNewAction}
                newActionTitle={'notificaciones.text.add'}
            >
                {Object.keys(filters).length > 0
                    ? <NotificacionesComponent
                        filters={filters}
                    />
                    : <div className={"w-full flex justify-center items-center py-4"}>
                        <CogIcon className={"h-4 w-4 text-gray-400 animate-spin"}/>
                    </div>

                }
            </PageBody>
        );
    }
;
