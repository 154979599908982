export const COLORS = [
    "#CAFE08",
    "#615F4D",
    "#7FC3D7",
    "#C3C43B",
    "#A4FDC7",
    "#1B8867",
    "#6976CC",
    "#5913DA",
    "#7FDFC0",
    "#242C4F",
    "#9C89BF",
    "#4B5DB2",
    "#FA5DC5",
    "#0CFC6D",
    "#39A272",
    "#0023A1",
    "#86D92B",
    "#BEE3C4",
    "#CE1DE2",
    "#4EF184",
    "#718788",
    "#426721",
    "#3A2EE1",
    "#361ED4",
    "#B0CCBE",
    "#D569DD",
    "#B394B8",
    "#F7CE25",
    "#87D165",
    "#B18AD7",
    "#8DF205",
    "#930778",
    "#642938",
    "#F448C1",
    "#CE62F1",
    "#E4F904",
    "#995B3F",
    "#235CA5",
    "#E5360E",
    "#45C4B9",
    "#1624F2",
    "#3327EF",
    "#5E0171",
    "#90AF12",
    "#AE87CE",
    "#8547E1",
    "#65C276",
    "#A575A2",
    "#7B3217",
    "#34F019",
    "#E4BAC5",
    "#281675",
    "#F65578",
    "#9300A3",
    "#AEF685",
    "#5AED9F",
    "#C34901",
    "#111D47",
    "#F60642",
    "#CDFE45",
    "#B2EAC0",
    "#754160",
    "#1806D6",
    "#401571",
    "#701E61",
    "#DB6766",
    "#2BBEE1",
    "#5BF45C",
    "#CB5B89",
    "#FC02C6",
    "#472C75",
    "#E6BD74",
    "#40C9B1",
    "#F504D5",
    "#2D3DFE",
    "#6B7CA3",
    "#E822DA",
    "#82E1BF",
    "#589273",
    "#6D5CCD",
    "#3278A4",
    "#87C929",
    "#8A438B",
    "#299E7C",
    "#DDF770",
    "#B72AE8",
    "#B3C233",
    "#670053",
    "#13640B",
    "#B90C27",
    "#E32770",
    "#968ABB",
    "#AFE9BE",
    "#7CEB7B",
    "#4857EB",
    "#90354C",
    "#024071",
    "#D4C538",
    "#8B35B8",
    "#2B1AB6",
]
