import {CalendarIcon, CurrencyEuroIcon, HomeIcon, UserIcon, UsersIcon} from '@heroicons/react/outline';
import {MenuNavigationItem, Roles} from '../types';

export const MENU_NAVIGATION_LINKS: (tipo: string) => MenuNavigationItem[] = (tipo: string) => ([
    {
        name: 'Operativa diaria',
        icon: UsersIcon,
        current: false,
        roles: [Roles.SuperAdmin, Roles.User, Roles.Auxiliar],
        children: [
            {
                name: 'Dashboard',
                href: '/',
                icon: HomeIcon,
                current: false,
                roles: [Roles.SuperAdmin, Roles.User],
            },
            {
                name: 'Buzón de entrada',
                href: '/notificaciones',
                icon: CalendarIcon,
                current: false,
                roles: [Roles.SuperAdmin, Roles.User, Roles.Auxiliar],
            },
            {
                name: 'Calendario',
                href: '/calendario',
                icon: CalendarIcon,
                current: false,
                roles: [Roles.SuperAdmin, Roles.User],
            },
        ],
    },
    {
        name: 'Reportes',
        roles: [Roles.SuperAdmin, Roles.User],
        children: [
            {
                name: 'Intervenciones',
                href: '/intervenciones-list',
                icon: CalendarIcon,
                current: false,
                roles: [Roles.SuperAdmin, Roles.User],
            },
            {
                name: 'Horas por operario',
                href: '/reporte-horas-operario',
                icon: HomeIcon,
                current: false,
                roles: [Roles.SuperAdmin],
            },
            {
                name: 'Horas por intervencion',
                href: '/reporte-horas-intervencion',
                icon: HomeIcon,
                current: false,
                roles: [Roles.SuperAdmin],
            },
            {
                name: 'Cobros',
                href: '/cobros',
                icon: CurrencyEuroIcon,
                roles: [Roles.SuperAdmin],
            },
            {
                name: 'Rutas',
                href: '/rutas',
                icon: CurrencyEuroIcon,
                roles: [Roles.SuperAdmin],
            }
        ]
    },
    {
        name: 'Equipos y Clientes',
        icon: UsersIcon,
        current: false,
        roles: [Roles.SuperAdmin],
        children: [
            {
                name: tipo ? 'Tipos de equipo' : 'Tipos de servicio',
                href: '/tipo-equipo',
                icon: UsersIcon,
                current: false,
                roles: [Roles.SuperAdmin, Roles.User],
            },
            {
                name: tipo ? 'Equipos' : 'Servicios',
                href: '/equipos',
                icon: HomeIcon,
                current: false,
                roles: [Roles.SuperAdmin, Roles.User],
            },
            {
                name: 'Clientes',
                href: '/clients',
                icon: UserIcon,
                roles: [Roles.SuperAdmin, Roles.User],
            },
        ]
    },
    {
        name: 'Gestión de usuarios',
        icon: UsersIcon,
        current: false,
        roles: [Roles.SuperAdmin],
        children: [
            {
                name: 'Operarios',
                href: '/operarios',
                icon: HomeIcon,
                current: false,
                roles: [Roles.SuperAdmin],
            },
            {
                name: 'Usuarios',
                href: '/usuarios',
                icon: HomeIcon,
                current: false,
                roles: [Roles.SuperAdmin],
            }]
    },
    {
        name: 'Administración',
        icon: UsersIcon,
        current: false,
        roles: [Roles.SuperAdmin],
        children: [
            {
                name: 'Configuracion',
                href: '/configuracion',
                icon: HomeIcon,
                current: false,
                roles: [Roles.SuperAdmin],
            },
            {
                name: 'Paises',
                href: '/countries',
                icon: HomeIcon,
                current: false,
                roles: [Roles.SuperAdmin],
            },
            {
                name: 'Provincias',
                href: '/provinces',
                icon: HomeIcon,
                current: false,
                roles: [Roles.SuperAdmin],
            },
            {
                name: 'Ciudades',
                href: '/cities',
                icon: HomeIcon,
                current: false,
                roles: [Roles.SuperAdmin],
            },
            {
                name: 'Tipos de intervencion',
                href: '/tipos-intervencion',
                icon: HomeIcon,
                current: false,
                roles: [Roles.SuperAdmin],
            },
            {
                name: 'Tipos de alertas del buzón',
                href: '/tipos-notificacion',
                icon: HomeIcon,
                current: false,
                roles: [Roles.SuperAdmin],
            },
            {
                name: 'Modos de Pago',
                href: '/modos-pago',
                icon: HomeIcon,
                current: false,
                roles: [Roles.SuperAdmin],
            },
            {
                name: 'Periodicidades',
                href: '/periodicidades',
                icon: HomeIcon,
                current: false,
                roles: [Roles.SuperAdmin],
            },
            {
                name: 'Categorias operarios',
                href: '/categorias-operario',
                icon: HomeIcon,
                current: false,
                roles: [Roles.SuperAdmin],
            },
            {
                name: 'Proveedores externos',
                href: '/proveedores-externos',
                icon: HomeIcon,
                current: false,
                roles: [Roles.SuperAdmin],
            },

        ]
    }
]);

export const MENU_OPERARIOS_NAVIGATION_LINKS: MenuNavigationItem[] = [
    {name: 'Home', href: '/', icon: HomeIcon, current: false}
];
