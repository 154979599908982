import {useState} from 'react';

import {useTranslation} from 'react-i18next';
import {XIcon} from '@heroicons/react/outline';

import {HelpProvider} from './contexts';
import {classNames} from "../../utils";
import Iframe from "react-iframe";

export const Help = ({children}: { children: JSX.Element }) => {
    const {t} = useTranslation();
    const [title, setTitle] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [url, setUrl] = useState<string>('');
    const [hasInternalClose, setHasInternalClose] = useState<boolean>(true);
    const [size, setSize] = useState<string>('lg');

    const onOpen = (
        title: string,
        url: string,
        hasClose: boolean = true,
        size: string = 'xl'
    ) => {
        setTitle(title);
        setHasInternalClose(hasClose);
        setSize(size);
        setUrl(url)
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);

        setTimeout(() => {
            setTitle('');
            setUrl('');
        }, 300);
    };

    return (
        <HelpProvider value={{open: onOpen, close: onClose}}>
            <div className={"flex"}>

                <div className={"flex-1"}>
                    {children}
                </div>

                <div className={classNames(
                    "p-0 m-0 h-fit border-l border-primary bg-white transition-all duration-500 box-content",
                    !open ? "w-0" : `w-[400px]`,
                )}
                >
                    {/*<div*/}
                    {/*    className="flex h-full flex-col overflow-y-scroll bg-white pb-0 shadow-xl ">*/}
                    <div
                        className="p-4 sm:p-6 flex items-center justify-between h-18">
                        <div className="text-sm font-medium text-gray-900">
                            {title === "" ? t("common.label.help") : t(title)}
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                            {hasInternalClose && (
                                <button
                                    type="button"
                                    className="text-black hover:text-black focus:outline-none focus:ring-2 focus:ring-transparent"
                                    onClick={() =>
                                        setOpen(false)
                                    }
                                >
                                    <XIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="relative">
                        {/*TODO: use iframe*/}
                        <Iframe
                            url={url}
                            width={"395px"}
                            height={"800px"}
                            display="block"
                            position="relative"
                        />
                    </div>
                </div>

            </div>

        </HelpProvider>
    );
};
