import {PageBody} from '../../elements/page-body';
import {IntervencionesComponent} from './intervenciones.component';
import {useSearchParams} from 'react-router-dom';
import {IntervencionForm} from './intervencion-form';
import {useDrawer} from '../../shared/drawer';
import {IntervencionFilter} from './intervencion-filter';
import {useState} from 'react';
import {PlanificarForm} from "./planificar-form";
import {HELP_URLS} from "../../constants";
import {INITIAL_FILTERS} from "./constants";

export const Intervenciones = () => {
    const [params] = useSearchParams();
    const {open, close} = useDrawer();

    const [filters, setFilters] = useState<any>(INITIAL_FILTERS);

    const handleNewAction = () => {
        open(
            'calendario.form.new-item',
            <IntervencionForm close={handleClose} cancel={() => close()}/>
        );
    };

    const handlePlanificaAction = () => {
        open(
            'calendario.form.planifica',
            <PlanificarForm close={handleClose}/>,
            true,
            '2xl'
        );
    };


    const handleClose = () => {
        close();
        window.location.reload();
    };

    const handleFilters = () => {
        open(
            'calendario.filter.title',
            <IntervencionFilter close={close} doFilter={doFilter} item={filters}/>
        );
    };

    const doFilter = (filters: any) => {
        close();
        if (Object.keys(filters).length > 0) {
            if (!filters.fecha_estimacion_inicio_after) {
                delete filters.fecha_estimacion_inicio_after
                delete filters.fecha_estimacion_inicio_before
            }
            if (!filters.estado) {
                delete filters.estado
            }
            setFilters(filters);
        } else {
            setFilters(INITIAL_FILTERS);
        }
    };

    return (
        <PageBody
            title={'calendario.text.title'}
            newAction={handleNewAction}
            newActionTitle={'calendario.button.new'}
            secondaryAction={handlePlanificaAction}
            secondaryActionTitle={'calendario.button.planificar'}
            // infoText={'calendario.text.infoText'}
            filterAction={handleFilters}
            helpUrl={HELP_URLS.global}
        >
            <IntervencionesComponent
                selected={params.get('selected')}
                filters={filters}
            />
        </PageBody>
    );
};
