import {useEffect, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {useHttpClient} from '../../shared/http-client';
import {FilterFooter} from '../../elements/filter-footer';
import {ESTADOS} from "./constants";
import {SelectAsync} from "../../elements/select-async";
import {DateRangePicker} from "../../elements/date-range-picker";
import dayjs from "dayjs";
import {Toggle} from "../../elements/toggle";
import {Input} from "../../elements/input";

// construccion del objecto yup de validacion del cuestionario
let obligado = {};
const validacion = Yup.object().shape(obligado);

const initialValues = {
    numero: '',
    done: false,
    cliente: null,
};

export const NotificacionesFilter = ({
                                         item,
                                         doFilter,
                                         close,
                                     }: {
    item?: any;
    close: () => void;
    doFilter: (item: any) => void;
}) => {

    const {t} = useTranslation();
    const {api, state, options} = useHttpClient();
    const [data, setData] = useState<any | null>(null);
    const [operarios, setOperarios] = useState<any | null>([]);
    const [estados, setEstados] = useState<any | null>([]);
    const [startDate, setStartDate] = useState<any | null>(null);
    const [endDate, setEndDate] = useState<any | null>(null);

    useEffect(() => {
        api('/operarios/?is_active=true&limit=9999', 'GET');
    }, []);

    useEffect(() => {
        if (item) {
            setData(item);
            setEstados(ESTADOS.map((item: any) => ({id: item.id, name: item.label})))
        }
    }, [item]);

    const beforeSubmit = (values: any | null) => {
        let after = {...values};

        if (values.cliente) {
            after.cliente = values.cliente.id;
        }

        if (startDate) {
            after.fecha_after = startDate;
        } else {
            delete after.fecha_after;
        }

        if (endDate) {
            after.fecha_before = endDate;
        } else {
            delete after.fecha_before;
        }

        return after;
    };

    const onSubmit = async (values: any | null) => {
        //
        const enviaremos = beforeSubmit(values);

        doFilter(enviaremos);
    };

    useEffect(() => {

        if (state.data) {
            if (state.path.includes('operarios')) {
                setOperarios(state.data.results);
            }
        }

        if (state.error) {
            toast.error(state.error.detail);
        }

    }, [state]);


    const handleDates = (dates: any) => {
        if (dates) {
            const start = dayjs(dates[0]);
            const end = dates[1]
                ? dayjs(dates[1]).add(1, 'day')
                : dayjs(dates[0]).add(1, 'day');
            if (dates[1]) {
                setStartDate(`${start.format('YYYY-MM-DD')}`)
                setEndDate(`${end.format('YYYY-MM-DD')}`)
            }
        } else {
            setStartDate(null)
            setEndDate(null)
        }
    };

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={data || initialValues}
                validationSchema={validacion}
                validateOnBlur={true}
                onSubmit={onSubmit}
            >
                {({
                      values,
                      errors,
                      setFieldValue,
                      handleSubmit,
                      isSubmitting
                  }) => (
                    <form onSubmit={handleSubmit} autoComplete="off">
                        {/*<pre>{JSON.stringify(values, null, 4)}</pre>*/}
                        {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}

                        <Toggle
                            value={values.done}
                            onChange={(value) => {
                                setFieldValue(
                                    'done',
                                    value.toggle
                                );
                            }}
                            label={t('notificacion.form.done')}
                        />

                        <DateRangePicker doChange={handleDates}/>

                        <div className={"pt-8"}/>

                        <SelectAsync
                            name={'cliente'}
                            label={'notificacion.form.cliente'}
                            placeholder="common.placeholder.select-value"
                            items={(inputValue: string) => {
                                return options(
                                    `/clientes/?limit=9999&name=${inputValue}&activo=true`
                                );
                            }}
                            value={values.cliente}
                            onChange={(value) => {
                                setFieldValue('cliente', value || {});
                                if (value) {
                                    api(
                                        `/instalaciones/?limit=999999&cliente=${value.id}`,
                                        'GET'
                                    );
                                }
                            }}
                        />

                        <Input
                            type="text"
                            name="name"
                            value={values.name}
                            label="common.label.name"
                            placeholder="common.label.name"
                            onChange={(value) => setFieldValue('name', value.name)}
                        />


                        <FilterFooter
                            item={item}
                            doDelete={() => doFilter({})}
                            doSubmit={handleSubmit}
                            close={close}
                        />
                    </form>
                )}
            </Formik>
        </div>
    );
};
