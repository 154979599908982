import React, {useEffect, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {useHttpClient} from '../../shared/http-client';
import {Select} from '../../elements/select';
import {FieldError} from "../../elements/field-error/field-error";
import {FormFooter} from "../../elements/form-footer";
import {StoreKeys} from "../../shared/store";
import {useDrawer} from "../../shared/drawer";
import {Toggle} from "../../elements/toggle";
import {SelectAsync} from "../../elements/select-async";
import {DateTimePicker} from "../../elements/date-time-picker";
import {Button} from "../../elements/button";
import {TextArea} from "../../elements/text-area";
import {Input} from "../../elements/input";
import {IntervencionForm} from "../intervenciones/intervencion-form";
import {useCookies} from "../../shared/cookies";

// construccion del objecto yup de validacion del cuestionario
let obligado = {
    // numero: Yup.string().required('obligatorio'),
    tipo_notificacion: Yup.number().required('obligatorio').nullable(),
    fecha: Yup.date().required('obligatorio').nullable(),
    email: Yup.string().email('Introducir email válido').nullable(),
    emisor: Yup.string().required('obligatorio').nullable(),
    // cliente: Yup.object().required('obligatorio').nullable(),
    // instalacion: Yup.number().required('obligatorio').nullable()
};
const validacion = Yup.object().shape(obligado);

const initialValues = {
    tipo_notificacion: null,
    done: false,
    is_privativa: false,

};

export const NotificacionForm = ({
                                     item,
                                     close,
                                     cancel,
                                     doDelete
                                 }: {
    item?: any;
    close: () => void;
    cancel: () => void;
    doDelete?: (item: any) => void;
}) => {
    const {t} = useTranslation();
    const {api, state, options} = useHttpClient();
    const {open} = useDrawer();
    const {get: getCookie} = useCookies();
    const [data, setData] = useState<any | null>(null);
    const [tiposNotificacion, setTiposNotificacion] = useState<any | null>(null);
    const [instalaciones, setInstalaciones] = useState<any | null>(null);
    const [profile, setProfile] = useState<any>({});
    const [cities, setCities] = useState<any | null>([]);
    const [provinces, setProvinces] = useState<any | null>([]);
    const [countries, setCountries] = useState<any | null>([]);
    const [province, setProvince] = useState<any | null>(null);
    const [country, setCountry] = useState<any | null>(null);

    useEffect(() => {
        api('/tipos-notificacion/?limit=9999', 'GET');

        // get connected user roles
        if (getCookie(StoreKeys.Token)) {
            setProfile(getCookie(StoreKeys.Profile));
        }
    }, []);

    useEffect(() => {
        if (item) {
            // console.log('item', item)
            const carga = {...item}
            carga.cliente = item.cliente ? item.cliente : null;
            carga.province = item.city ? item.city.province.id : null;
            carga.country = item.city ? item.city.province.country.id : null;
            setData(carga);
        }
    }, [item]);

    const beforeSubmit = (values: any | null) => {
        let after = {...values};

        // console.log('beforeSubmit', values);

        after.cliente = after.cliente ? after.cliente.id : null;
        after.instalacion = after.instalacion ? after.instalacion : null;
        after.province = after.province ? after.province : null;
        after.city = after.city ? typeof after.city === 'object' ? after.city.id : after.city : null;
        after.intervencion = after.intervencion ? after.intervencion.id : null;
        after.tipo_notificacion = after.tipo_notificacion ? after.tipo_notificacion : null;

        after.owner = profile.id;


        return after;
    };

    const onSubmit = async (values: any | null) => {
        //

        const enviaremos = beforeSubmit(values);

        // console.log('enviaremos', enviaremos);

        if (enviaremos.id) {
            // update
            api(`/notificaciones/${enviaremos.id}/`, 'PATCH', enviaremos);
        } else {
            // create
            api('/notificaciones/', 'POST', enviaremos);
        }
    };

    useEffect(() => {
        if (state.data) {
            if (state.path.includes('tipos-notificacion')) {
                setTiposNotificacion(state.data.results);
                if (item) {
                    console.log('tipos-notificacion', item)
                    if (item.instalacion) {
                        api(`/instalaciones/?limit=999&cliente=${item.cliente.id}`, 'GET');
                    } else {
                        api('/instalaciones/?limit=1', 'GET');
                    }
                }
            }

            if (state.path.includes('instalaciones')) {
                setInstalaciones(state.data.results);
                api('/paises/?limit=999999', 'GET');
            }

            if (state.path.includes('paises')) {
                setCountries(state.data.results);
            }

            if (state.path.includes('provincias')) {
                setProvinces(state.data.results);
            }

            if (state.path.includes('ciudades')) {
                if (province) {
                    setCities(state.data.results);
                }
            }

            if (state.path.includes('clientes')) {

                // crea la instalacion del cliente
                const data = state.data;
                const cliente = data.id

                delete data.cliente
                delete data.codigo
                delete data.nif
                delete data.email
                delete data.contact
                delete data.contact_city
                delete data.contact_province
                delete data.activo
                delete data.id

                const enviaremos = {
                    ...data,
                    name: data.name,
                    cliente,
                }

                api(`/instalaciones/crea_instalacion/`, 'POST', enviaremos)
            }

            if (state.path.includes('crea_instalacion')) {

                // actualiza la ficha del cliente con la instalacion creada
                const datos = state.data;
                api(`/instalaciones/?limit=999&cliente=${state.data.cliente.id}`, 'GET');
                setTimeout(() => {
                    setData((prev: any) => ({
                        ...prev,
                        cliente: datos.cliente,
                        instalacion: datos,
                    }));
                }, 500);
            }

            if (state.path.includes('notificaciones')) {
                close();
            }
        }

        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [state]);

    useEffect(() => {
        if (country) {
            api(`/provincias/?pais=${country}&limit=999999`, 'GET');
        }
    }, [country]);

    useEffect(() => {
        if (province) {
            api(`/ciudades/?provincia=${province}&limit=999999`, 'GET');
        }
    }, [province]);

    const createIntervencion = (values: any) => {
        close()
        setTimeout(() => {
            open(
                'calendario.form.reprograma-item',
                <IntervencionForm
                    item={{
                        cliente: values.cliente,
                        instalacion: values.instalacion,
                        prioridad: 'C',
                        horas_estimadas: 1,
                    }}
                    close={() => {
                        api(`/notificaciones/${item.id}/`, 'PATCH', {done: true});
                        close();
                    }}
                    cancel={close}/>
            );
        }, 1000);
    }

    const createCliente = (values: any) => {
        console.log('createCliente', values);
        const cliente = {
            city: values.city,
            province: values.province,
            name: values.name,
            nif: values.nif,
            direccion: values.direccion,
            cp: values.cp,
            telefono: values.telefono,
            movil: values.movil,
            email: values.email,
            country: values.country,
        }
        api('/clientes/', 'POST', cliente);
    }

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={data || initialValues}
                validationSchema={validacion}
                validateOnBlur={true}
                onSubmit={onSubmit}
            >
                {({
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit} autoComplete="off">
                        {/*<pre>{JSON.stringify(values, null, 4)}</pre>*/}
                        {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}
                        <Toggle
                            value={values.done}
                            onChange={(value) => {
                                setFieldValue(
                                    'done',
                                    value.toggle
                                );
                            }}
                            label={t('notificacion.form.done')}
                        />

                        <div className="w-full">
                            <div>
                                <Select
                                    name="tipo_notificacion"
                                    label={t('notificacion.form.tipo_notificacion')}
                                    placeholder="common.placeholder.select-value"
                                    items={tiposNotificacion}
                                    value={values.tipo_notificacion}
                                    onChange={(value) => {
                                        setFieldValue(
                                            'tipo_notificacion',
                                            value.tipo_notificacion
                                        );
                                    }}
                                />
                                <FieldError touched={touched} errors={errors} field={"tipo_notificacion"}/>
                            </div>
                        </div>

                        <DateTimePicker
                            type={'time'}
                            value={values.fecha}
                            name={'fecha'}
                            withTimeControl={false}
                            timeIntervals={15}
                            label={t(
                                'notificacion.form.fecha'
                            )}
                            onChange={(value) => {
                                setFieldValue(
                                    'fecha',
                                    value.fecha
                                );
                            }}
                        />
                        <FieldError touched={touched} errors={errors} field={"fecha"}/>

                        <Input
                            type="text"
                            name="emisor"
                            value={values.emisor}
                            label="notificacion.form.emisor"
                            placeholder="notificacion.form.emisor"
                            onChange={(value) => {
                                setFieldValue('emisor', value.emisor)
                                setFieldValue('name', value.emisor)
                            }}
                        />
                        <FieldError touched={touched} errors={errors} field={"emisor"}/>

                        <div className={"p-4"}></div>

                        <Toggle
                            value={values.is_privativa}
                            onChange={(value) => {
                                setFieldValue(
                                    'is_privativa',
                                    value.toggle
                                );
                            }}
                            label={t('notificacion.form.is_privativa')}
                        />

                        <SelectAsync
                            name={'cliente'}
                            label={'notificacion.form.cliente'}
                            items={(inputValue: string) => {
                                return options(
                                    `/clientes/?limit=9999&name=${inputValue}&activo=true`
                                );
                            }}
                            value={values.cliente}
                            onChange={(value) => {
                                setFieldValue('cliente', value || {});
                                if (value) {
                                    api(
                                        `/instalaciones/?limit=999999&cliente=${value.id}`,
                                        'GET'
                                    );
                                }
                            }}
                        />
                        <FieldError touched={touched} errors={errors} field={"cliente"}/>

                        <Select
                            name="instalacion"
                            label={t('notificacion.form.instalacion')}
                            placeholder="common.placeholder.select-value"
                            items={instalaciones}
                            value={values.instalacion}
                            disabled={!values.cliente}
                            onChange={(value) =>
                                setFieldValue(
                                    'instalacion',
                                    value.instalacion
                                )
                            }
                        />
                        <FieldError touched={touched} errors={errors} field={"instalacion"}/>

                        {/*ES UN CLIENTE PRIVATIVO (Y NO TIENE CLIENTE)*/}
                        {values.is_privativa && !(values.cliente && Object.keys(values.cliente).length !== 0)
                            ? (
                                <>
                                    <hr className={"py-4"}/>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={values.name}
                                        label="common.label.name"
                                        placeholder="common.label.name"
                                        onChange={(value) => setFieldValue('name', value.name)}
                                    />
                                    <FieldError touched={touched} errors={errors} field={"name"}/>

                                    <Input
                                        type="email"
                                        name="email"
                                        value={values.email}
                                        label="common.label.email"
                                        placeholder="common.label.email"
                                        onChange={(value) =>
                                            setFieldValue('email', value.email)
                                        }
                                    />
                                    <FieldError touched={touched} errors={errors} field={"email"}/>

                                    <div className="flex space-x-[24px]">
                                        <div className="w-1/2">
                                            <Input
                                                type="text"
                                                name="telefono"
                                                value={values.telefono}
                                                label="configuracion.form.telefono"
                                                placeholder="configuracion.form.telefono"
                                                onChange={(value) =>
                                                    setFieldValue('telefono', value.telefono)
                                                }
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <Input
                                                type="text"
                                                name="movil"
                                                value={values.movil}
                                                label="configuracion.form.movil"
                                                placeholder="configuracion.form.movil"
                                                onChange={(value) =>
                                                    setFieldValue('movil', value.movil)
                                                }
                                            />
                                        </div>
                                    </div>


                                    <div className="flex space-x-[24px]">
                                        <div className="w-full">
                                            <Input
                                                type="text"
                                                name="direccion"
                                                value={values.direccion}
                                                label="common.label.direction"
                                                placeholder="common.label.direction"
                                                onChange={(value) =>
                                                    setFieldValue('direccion', value.direccion)
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="flex space-x-[24px]">
                                        <div className="w-1/2">
                                            <Input
                                                type="text"
                                                name="cp"
                                                value={values.cp}
                                                label="common.label.postal-code"
                                                placeholder="common.label.postal-code"
                                                onChange={(value) =>
                                                    setFieldValue('cp', value.cp)
                                                }
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <Input
                                                type="text"
                                                name="nif"
                                                value={values.nif}
                                                label="common.label.nif"
                                                placeholder="common.label.nif"
                                                onChange={(value) =>
                                                    setFieldValue('nif', value.nif)
                                                }
                                            />
                                            <FieldError touched={touched} errors={errors} field={"nif"}/>
                                        </div>
                                    </div>

                                    <div className="flex flex-col">
                                        <Select
                                            label={t('provincia.form.country')}
                                            placeholder="common.placeholder.select-value"
                                            items={countries}
                                            name="country"
                                            value={values.country}
                                            onChange={(value) => {
                                                setFieldValue('country', value.country)
                                                console.log("COUNTRY", value.country)
                                                setCountry(value.country)
                                            }}
                                        />
                                        <FieldError touched={touched} errors={errors} field={"country"}/>
                                        <Select
                                            name="province"
                                            disabled={!provinces.length}
                                            label={t('common.label.province')}
                                            placeholder="common.label.select-value"
                                            items={provinces}
                                            value={values.province}
                                            onChange={(value) => {
                                                setProvince(value.province);
                                                setFieldValue('province', value.province);
                                            }}
                                        />
                                        <div className="">
                                            <Select
                                                name="city"
                                                disabled={!cities.length}
                                                label={t('common.label.city')}
                                                placeholder="common.label.select-value"
                                                items={cities}
                                                value={values.city}
                                                onChange={(value) =>
                                                    setFieldValue('city', value.city)
                                                }
                                            />
                                        </div>
                                    </div>

                                    {item?.id && values.name
                                        ? (

                                            <div className={"flex flex-row space-x-2 mb-4"}>
                                                <Button
                                                    variant={"secondary"}
                                                    onClick={() => createCliente(values)}
                                                    label={t('notificacion.form.crear-cliente')}
                                                />
                                            </div>)
                                        : null
                                    }

                                </>
                            )
                            : null
                        }
                        {/*ES UN CLIENTE PRIVATIVO*/}

                        <TextArea
                            name={'observaciones'}
                            label={t('notificacion.form.observaciones')}
                            value={values.observaciones}
                            onChange={(value) =>
                                setFieldValue(
                                    'observaciones',
                                    value.observaciones
                                )
                            }
                        />

                        {values.cliente && values.instalacion
                            ? (

                                <div className={"flex flex-row space-x-2 mb-4"}>
                                    <Button
                                        variant={"secondary"}
                                        onClick={() => createIntervencion(values)}
                                        label={t('notificacion.form.crear-intervencion')}
                                    />
                                </div>)
                            : null
                        }


                        <div className={"py-16"}/>

                        <FormFooter
                            item={item}
                            doDelete={doDelete}
                            doSubmit={handleSubmit}
                            close={close}
                            isSubmitting={values.estado === "CERRADO"}
                        />
                    </form>
                )}
            </Formik>
        </div>
    )
        ;
};
