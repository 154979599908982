import React, {useEffect, useState} from 'react';

import {RoutesFilters} from './routes-filters';
import {useDrawer} from '../../../shared/drawer';
import {PageBody} from '../../../elements/page-body';
import {useHttpClient} from '../../../shared/http-client';
import {HELP_URLS} from "../../../constants";

import {MapContainer, Polyline, TileLayer} from "react-leaflet";

const INITIAL_FILTERS = {}

export const ReportRoutes = () => {
    const {open, close} = useDrawer();
    const {api, state} = useHttpClient();
    const [map, setMap] = useState<any>(null);
    const [operator, setOperator] = useState<any>(null);
    const [filters, setFilters] = useState<any>(INITIAL_FILTERS);
    const [cordCenter, setCordCenter] = useState({lat: 40.416775, lng: -3.703790})
    const [line, setLine] = useState([])

    const doFilter = (filters: any) => {
        close();
        if (Object.keys(filters).length > 0) {
            setFilters(filters);
        } else {
            setFilters(INITIAL_FILTERS);
        }
    }

    const handleFilters = () => {
        open(
            'calendario.filter.title',
            <RoutesFilters close={close} doFilter={doFilter} item={filters}/>,
            true,
            '2xl'
        );
    };

    useEffect(() => {
        if (filters.intervencion?.id) {
            api(`/localizaciones/?intervencion=${filters.intervencion?.id}&operario=${filters.operario}&limit=9999`, 'GET');
        }
    }, [filters]);

    useEffect(() => {
        if (state.data) {
            if (state.path.includes('localizaciones')) {
                if (state.data.results.length) {
                    setOperator({
                        strokeColor: '#FF0000',
                        points: state.data.results.map((item: any) => ({
                            lat: item.lat,
                            lng: item.lon
                        })),
                    });
                }
            }
        }
    }, [state])

    useEffect(() => {
        if (operator) {
            setCordCenter(operator.points[0]);
            const polyline = Object.keys(filters).length
                ? operator.points
                : [];
            setLine(polyline);
        } else {
            setCordCenter(cordCenter);
            setLine([]);
        }
    }, [filters, operator]);


    return (<div className="flex h-full flex-col">
        <PageBody
            filterAction={handleFilters}
            title={'intervenciones.tabs.mapa'}
            helpUrl={HELP_URLS.global}
        >
            <div style={{height: '60vh', width: '100%'}}>
                <MapContainer
                    center={[cordCenter?.lat, cordCenter?.lng]}
                    zoom={14}
                    scrollWheelZoom
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Polyline pathOptions={{color: operator?.strokeColor ?? "red"}} positions={line}/>
                </MapContainer>
            </div>
        </PageBody>
    </div>);
}
