import React, {useEffect, useRef, useState} from 'react';

import {Formik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';

import {Input} from '../../elements/input';
import {useTranslation} from 'react-i18next';
import {useHttpClient} from '../../shared/http-client';
import {Select} from '../../elements/select';
import {Toggle} from '../../elements/toggle';
import {DateTimePicker} from '../../elements/date-time-picker';
import {SelectAsync} from '../../elements/select-async';
import {TextArea} from '../../elements/text-area';
import {FieldError} from "../../elements/field-error/field-error";
import {FormFooter} from "../../elements/form-footer";
import {StoreKeys, useStore} from "../../shared/store";
import dayjs from "dayjs";
import {noop} from "../../utils";
import {PRIORIDADES, PRIORITIES_VALUES} from "./constants";

// construccion del objecto yup de validacion del cuestionario
let obligado = {
    tipo_intervencion: Yup.number().required('obligatorio').nullable(),
    fecha_estimacion_inicio: Yup.date().required('obligatorio').nullable(),
    cliente: Yup.object().required('obligatorio').nullable(),
};
const validacion = Yup.object().shape(obligado);

const initialValues = {
    facturar: false,
    horas_estimadas: 1,
    cliente: null,
    instalacion: null,
    tipo_intervencion: null,
    fecha_estimacion_inicio: null,
    estado: 'PENDIENTE',
    is_check: false,
    prioridad: 'C',
    operario: null,
    descripcion: '',
    is_tiempos_operacion: false,
};

export const IntervencionReprogramaForm = ({
                                               origen,
                                               close,
                                               cancel = noop
                                           }: {
    origen?: any;
    close: () => void;
    cancel: () => void;
}) => {
    const {t} = useTranslation();
    const {api, state, options} = useHttpClient();
    const {get: getCookie} = useStore();
    const [tiposIntervencion, setTiposIntervencion] = useState<any | null>(null);
    const [instalaciones, setInstalaciones] = useState<any | null>(null);
    const [operarios, setOperarios] = useState<any | null>(null);
    const [profile, setProfile] = useState<any>({});
    const [data, setData] = useState<any>({});

    console.log('origen', origen)

    useEffect(() => {
        if (origen) {
            const origenObj = {
                cliente: origen.cliente,
                instalacion: origen.instalacion,
                horas_estimadas: 1,
                prioridad: 'C',
                operario: origen.operario,
                descripcion: origen.descripcion,
            }
            setData(origenObj);
        }
    }, [origen]);

    let isCreando = useRef<boolean>(false)

    useEffect(() => {
        api('/tipos-intervencion/?limit=9999', 'GET');

        // get connected user roles
        if (getCookie(StoreKeys.Token)) {
            setProfile(getCookie(StoreKeys.Profile));
        }
    }, []);

    const beforeSubmit = (values: any | null) => {
        let after = {...values};

        // console.log('beforeSubmit', values);

        const hora = dayjs(values.fecha_estimacion_inicio).get('hour');
        if (hora === 0) {
            after.fecha_estimacion_inicio = dayjs(values.fecha_estimacion_inicio).set('hour', 7).format("YYYY-MM-DDTHH:mm:ssZ");
        }

        after.cliente = after.cliente ? after.cliente.id : null;
        after.intervencion = after.intervencion ? after.intervencion.id : null;
        after.prioridad = typeof values.prioridad === 'object'
            ? (PRIORIDADES.find((p) => p.value === values.prioridad)?.value)
            : values.prioridad

        after.anterior = origen.id;

        delete after.operarios;
        delete after.firma_operario;

        return after;
    };

    const onSubmit = async (values: any | null) => {
        // preparamos los datos a enviar en la nueva intervencion
        const enviaremos = beforeSubmit(values);

        console.log('onSubmit', enviaremos);

        // marcamos la anterior como cerrada y luego creamos la nueva
        api(`/intervenciones/${origen.id}/`, 'PATCH', {estado: 'CERRADO'});
        api('/intervenciones/', 'POST', enviaremos);
    };


    useEffect(() => {
        if (state.data) {
            if (state.path.includes('tipos-intervencion')) {
                setTiposIntervencion(state.data.results);
                if (origen) {
                    api(`/instalaciones/?limit=999999&cliente=${origen?.cliente?.id}`, 'GET');
                } else {
                    api(`/operarios/?is_active=true`, 'GET');
                }
            }

            if (state.path.includes('instalaciones')) {
                setInstalaciones(state.data.results);
                api(`/operarios/?is_active=true`, 'GET');
            }

            if (state.path.includes('operarios')) {
                setOperarios(state.data.results);
            }

            if (state.path.includes('intervenciones')) {
                close();
            }
        }

        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [state]);

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={data || initialValues}
                validationSchema={validacion}
                validateOnBlur={true}
                onSubmit={onSubmit}
            >
                {({
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit} autoComplete="off">
                        {/*<pre>{JSON.stringify(values, null, 4)}</pre>*/}
                        {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}

                        <div className="grid grid-cols-2 gap-1 justify-between items-center w-full">
                            <div>
                                <Select
                                    name="tipo_intervencion"
                                    label={t('intervencion.form.tipo_intervencion')}
                                    placeholder="common.placeholder.select-value"
                                    items={tiposIntervencion || []}
                                    value={values.tipo_intervencion}
                                    onChange={(value) => {
                                        setFieldValue(
                                            'tipo_intervencion',
                                            value.tipo_intervencion
                                        );
                                    }}
                                />
                                <FieldError touched={touched} errors={errors} field={"tipo_intervencion"}/>
                            </div>
                            <div>
                                <Select
                                    name="prioridad"
                                    label={t('intervencion.form.prioridad')}
                                    placeholder="common.placeholder.select-value"
                                    items={PRIORITIES_VALUES || []}
                                    value={typeof values.prioridad !== 'object'
                                        ? PRIORITIES_VALUES.find((e: {
                                            id: string,
                                            name: string
                                        }) => e.id === values.prioridad)
                                        : values.prioridad
                                    }
                                    onChange={(value) => {
                                        setFieldValue(
                                            'prioridad',
                                            value.prioridad
                                        );
                                    }}
                                />
                            </div>
                        </div>

                        <Input
                            name="horas_estimadas"
                            type="number"
                            label={t(
                                'intervention-hours.label.estimated-hours'
                            )}
                            placeholder={t(
                                'intervention-hours.label.estimated-hours'
                            )}
                            value={values.horas_estimadas}
                            onChange={(value) =>
                                setFieldValue(
                                    'horas_estimadas',
                                    value.horas_estimadas
                                )
                            }
                        />


                        <DateTimePicker
                            type={'time'}
                            value={values.fecha_estimacion_inicio}
                            name={'fecha_estimacion_inicio'}
                            label={t(
                                'intervencion.form.fecha_estimacion_inicio'
                            )}
                            onChange={(value) => {
                                setFieldValue(
                                    'fecha_estimacion_inicio',
                                    value.fecha_estimacion_inicio
                                );
                            }}
                        />
                        <FieldError touched={touched} errors={errors} field={"fecha_estimacion_inicio"}/>

                        <div className="grid grid-cols-2 gap-1 justify-between items-center w-full">
                            <div>
                                <SelectAsync
                                    name={'cliente'}
                                    label={'intervencion.form.cliente'}
                                    placeholder="common.placeholder.select-value"
                                    items={(inputValue: string) => {
                                        return options(
                                            `/clientes/?limit=9999&name=${inputValue}&activo=true`
                                        );
                                    }}
                                    value={values.cliente}
                                    onChange={(value) => {
                                        setFieldValue('cliente', value || {});
                                        if (value) {
                                            api(
                                                `/instalaciones/?limit=999999&cliente=${value.id}`,
                                                'GET'
                                            );
                                        }
                                    }}
                                />
                                <FieldError touched={touched} errors={errors} field={"cliente"}/>
                            </div>

                            <Select
                                name="instalacion"
                                label={t('intervencion.form.instalacion')}
                                placeholder="common.placeholder.select-value"
                                items={instalaciones || []}
                                value={values.instalacion}
                                disabled={!values.cliente}
                                onChange={(value) =>
                                    setFieldValue(
                                        'instalacion',
                                        value.instalacion
                                    )
                                }
                            />
                        </div>
                        <FieldError touched={touched} errors={errors} field={"isntalacion"}/>

                        <Toggle
                            value={values.is_tiempos_operacion}
                            onChange={(value) => {

                                setFieldValue(
                                    'is_tiempos_operacion',
                                    value.toggle
                                )

                                if (values.is_check && value.toggle) {
                                    setFieldValue('is_check', false)
                                }
                            }}
                            label={t('intervencion.form.is_tiempos_operacion')}
                        />

                        <Toggle
                            value={values.is_check}
                            onChange={(value) => {
                                setFieldValue(
                                    'is_check',
                                    value.toggle
                                );

                                if (values.is_tiempos_operacion && value.toggle) {
                                    setFieldValue('is_tiempos_operacion', false)
                                }
                            }}
                            label={t('intervencion.form.is_tiempos_validation')}
                        />

                        <Toggle
                            value={values.facturar}
                            onChange={(value) =>
                                setFieldValue('facturar', value.toggle)
                            }
                            label={t('intervencion.form.facturar')}
                        />

                        <TextArea
                            label={t('intervencion.form.descripcion')}
                            name={'descripcion'}
                            value={values.descripcion}
                            onChange={(value) =>
                                setFieldValue('descripcion', value.descripcion)
                            }
                        />

                        <Select
                            value={
                                values?.operario as any instanceof Array
                                    ? values?.operario?.[0]
                                    : values.operario
                            }
                            label={t('intervencion.form.operario')}
                            placeholder="common.placeholder.select-value"
                            items={operarios || []}
                            name="operario"
                            onChange={(value) =>
                                setFieldValue('operario', value.operario)
                            }
                        />

                        <div className={"py-24"}/>

                        <FormFooter
                            doSubmit={handleSubmit}
                            close={close}
                            isSubmitting={values.estado === "CERRADO"}
                        />
                    </form>
                )}
            </Formik>
        </div>
    );
};
