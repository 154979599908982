import dayjs from "dayjs";

export type EstadosTypes = {
    id: number,
    value: string,
    label: string
};

export const ESTADOS: EstadosTypes[]  = [
    { id: 1, value: 'PENDIENTE', label: 'Pendiente' },
    { id: 2, value: 'ASIGNADO', label: 'Asignado' },
    { id: 3, value: 'PROCESO', label: 'En proceso' },
    { id: 7, value: 'REVISAR', label: 'En revisión' },
    { id: 4, value: 'FINALIZADO', label: 'Finalizado' },
    { id: 5, value: 'CERRADO', label: 'Cerrado' },
]

export type PrioridadesTypes = {
    id: number,
    name: string,
    value?: string,
};
export const PRIORIDADES: PrioridadesTypes[]  = [
    { id: 1, name: 'URGENTE', value: "A" },
    { id: 2, name: 'ALTA', value: "B" },
    { id: 3, name: 'NORMAL', value: "C" },
    { id: 4, name: 'BAJA', value: "D" },
]

export const PRIORITIES_VALUES = [
    {id: 'A', name: 'Urgente'},
    {id: 'B', name: 'Alta'},
    {id: 'C', name: 'Normal'},
]

export const INITIAL_FILTERS = {
    fecha_estimacion_inicio_after: dayjs().format('YYYY-MM-DD'),
    fecha_estimacion_inicio_before: dayjs().add(1, 'days').format('YYYY-MM-DD')
}
