import * as Yup from 'yup';

import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Select } from '../../../elements/select';
import { useHttpClient } from '../../../shared/http-client';
import { SelectAsync } from '../../../elements/select-async';
import { FilterFooter } from '../../../elements/filter-footer';

const validations = Yup.object().shape({});

const initialValues = {
    intervencion: null,
    operario: null,
};

export const RoutesFilters = ({ item, doFilter, close }: {
    item?: any;
    close: () => void;
    doFilter: (item: any) => void;
}) => {
    const { t } = useTranslation();
    const { api, state, options } = useHttpClient();
    const [ data, setData ] = useState<any | null>(null);
    const [ operators, setOperators ] = useState<any | null>([]);

    useEffect(() => {
        api('/operarios/?limit=9999', 'GET');
    }, []);

    useEffect(() => {
        if (item) {
            setData(item);
        }
    }, [ item ]);

    const onSubmit = async (values: any | null) => {
        doFilter(values);
    };

    useEffect(() => {
        if (state.data) {
            if (state.path.includes('operarios')) {
                setOperators(state.data.results);
            }
        }

        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [ state ]);

    return (<div>
            <Formik
                enableReinitialize={true}
                initialValues={data || initialValues}
                validationSchema={validations}
                validateOnBlur={true}
                onSubmit={onSubmit}
            >
                {({
                      values, errors, setFieldValue, handleSubmit, isSubmitting
                  }) => (<form autoComplete="off">

                    <div className={"bg-gray-200 rounded-2xl p-4 mb-8 font-bold"}>
                        {t("common.map.instructions")}
                    </div>
                        <Select
                            name="operario"
                            label={t('intervencion.form.operario')}
                            placeholder="Select value"
                            items={operators}
                            value={values.operario}
                            onChange={(value) => {
                                setFieldValue('operario', value.operario);
                            }}
                        />

                        <SelectAsync
                            name={'intervencion'}
                            label={'intervencion.form.cliente'}
                            placeholder="Select value"
                            items={(inputValue: string) => {
                                return options(`/intervenciones/?limit=9999&name=${inputValue}`);
                            }}
                            getOptionsLabel={(e: any) => e.id ? `${e.id} - ${e.cliente?.name}` : ''}
                            value={values.intervencion}
                            onChange={(value: any) => {
                                setFieldValue('intervencion', value || {});
                            }}
                        />

                        <FilterFooter
                            item={item}
                            doDelete={() => doFilter({})}
                            doSubmit={handleSubmit}
                            close={close}
                        />
                    </form>)}
            </Formik>
        </div>);
};
