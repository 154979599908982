import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {useDrawer} from '../../../../shared/drawer';
import {PageBody} from '../../../../elements/page-body';
import {useHttpClient} from '../../../../shared/http-client';
import {MapsFilterForm} from '../../maps-filter-form';

import {MapContainer, Polyline, TileLayer} from "react-leaflet";
import {COLORS} from "../../../../utils/colors";

const INITIAL_FILTERS = {}

export const MapsComponent = () => {
    const {id} = useParams();
    const {open, close} = useDrawer();
    const {api, state} = useHttpClient();
    const [map, setMap] = useState<any>(null);
    const [filters, setFilters] = useState<any>({});
    const [markers, setMarkers] = useState<any>([]);
    const [refresh, setRefresh] = useState<string | null>(null);
    const [coordCenter, setCoordCenter] = useState({lat: 40.416775, lng: -3.703790}); // TODO: get from user

    const handleClose = () => {
        setRefresh(Date.now().toString());
        close();
    }

    const doFilter = (filters: any) => {
        close();
        if (Object.keys(filters).length > 0) {
            setFilters(filters);
        } else {
            setFilters(INITIAL_FILTERS);
        }
    }

    const handleSubmit = (values: any) => {
        setFilters(values);
        close();
    }

    const handleOpenFilters = () => {
        open(
            'intervenciones.drawer.filters',
            <MapsFilterForm
                doFilter={doFilter}
                close={handleClose}
                item={filters}
            />
        );
    }

    useEffect(() => {
        api(`/localizaciones/?intervencion=${id}&limit=9999`, 'GET');
    }, []);

    useEffect(() => {
        if (Object.keys(filters).length) {
            const query = Object.keys(filters).map(key => `${key}=${filters[key]}`).join('&');

            api(`/localizaciones/?intervencion=${id}&limit=9999&${query}`, 'GET');
        }
    }, [filters]);

    useEffect(() => {
        if (markers.length) {
            const center = markers[0];
            setCoordCenter({lat: center.lat, lng: center.lon});
        }
    }, [markers]);

    useEffect(() => {
        if (map) {
            map.setCenter(coordCenter);
        }
    }, [coordCenter]);

    useEffect(() => {
        if (state.data) {
            if (state.path.includes('localizaciones')) {
                let operarios: any[] = []
                for (const intervencion of state.data.results) {
                    let isIn = operarios.find((item: any) => (item.id === intervencion.operario.id))
                    if (!isIn) {
                        operarios.push(intervencion.operario)
                    }
                }
                for (const operario of operarios) {
                    let points: any[] = []
                    for (const intervencion of state.data.results) {
                        if (operario.id === intervencion.operario.id) {
                            points.push({
                                lat: intervencion.lat,
                                lng: intervencion.lon
                            })
                        }
                    }
                    operario.points = points
                }
                setMarkers(operarios)

            }
        }
    }, [state]);

    return (<div className="flex h-full flex-col">
        <PageBody
            filterAction={handleOpenFilters}
            title={'intervenciones.tabs.mapa'}
        >
            <div style={{height: '60vh', width: '100%'}}>
                <MapContainer
                    center={[coordCenter?.lat, coordCenter?.lng]}
                    zoom={14}
                    scrollWheelZoom
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {markers.length > 0 && markers?.map((operario: any, index: number) => (
                        <Polyline weight={5} pathOptions={{color: `${COLORS[index]}`}} positions={operario.points}/>
                    ))
                    }
                </MapContainer>
            </div>
        </PageBody>
    </div>);
};
