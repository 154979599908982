import {useState} from 'react';

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import isoWeek from 'dayjs/plugin/isoWeek';
import {Table} from '../../../../elements/table';
import {useNavigate, useParams} from 'react-router-dom';
import {IntervencionForm} from '../../intervencion-form';

export const TrazaComponent = () => {
    dayjs.extend(localeData);
    dayjs.extend(weekday);
    dayjs.extend(isoWeek);

    const {id} = useParams();
    const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState(dayjs());

    const handleItemClick = (item: any) => {
        navigate(`/intervenciones/${item.id}/tareas`, {state: "intervenciones"});
    };

    return (
        <div className="flex h-full flex-col">
            <Table
                path={`/intervenciones/${id}/traza`}
                Form={IntervencionForm}
                onShow={handleItemClick}
                headers={[
                    {
                        key: 'numero',
                        label: 'intervenciones.table.numero'
                    },
                    {
                        key: 'fecha_inicio',
                        label: 'intervenciones.table.fecha_estimacion_inicio',
                        type: 'datetime'
                    },
                    {
                        key: 'horas_reales',
                        label: 'intervenciones.table.horas_reales',
                        type: 'decimal',
                        suffix: 'h',
                    },
                    {
                        key: 'cliente',
                        label: 'intervenciones.table.cliente'
                    },
                    {
                        key: 'instalacion',
                        label: 'intervenciones.table.instalacion'
                    },
                    {
                        key: 'tipo_intervencion',
                        label: 'intervenciones.table.tipo_intervencion'
                    },
                    {
                        key: 'operarios',
                        label: 'intervenciones.table.operarios'
                    },
                    {
                        key: 'estado',
                        type: 'tag',
                        label: 'intervenciones.table.estado'
                    }
                ]}
            />
        </div>
    );
};
