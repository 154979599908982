import {classNames, estatusCardColor} from "../../../utils";
import {useTranslation} from "react-i18next";

export const CalendarLegendItem = ({estado}: {estado: string}) => {
    const {t} = useTranslation();
    return (
        <div className="flex flex-row space-x-2 items-center">
            <div className={classNames(
                "h-3 w-3 rounded-full",
                estatusCardColor({estado})
            )}></div>
            <div className={"text-[11px]"}>{t(estado)}</div>
        </div>

    )
}
