import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import isoWeek from 'dayjs/plugin/isoWeek';
import {Table} from '../../elements/table';
import {useNavigate} from 'react-router-dom';
import {NotificacionForm} from './notificacion-form';
import {getQueryString} from '../../utils';
import {useTranslation} from "react-i18next";
import React from "react";

export const NotificacionesComponent = ({
                                            filters
                                        }: {
    filters: any;
}) => {
    dayjs.extend(localeData);
    dayjs.extend(weekday);
    dayjs.extend(isoWeek);

    const {t} = useTranslation();
    const navigate = useNavigate();

    const handleItemClick = (item: any) => {
        navigate(`/notificaciones/${item.id}/tareas`, {state: "notificaciones"});
    };

    const renderFilters = (filters: any) => {
        let render = []
        for (const key in filters) {
            render.push(<div key={key} className="flex flex-row">
                <div className="flex-1">{t(key)}</div>
                <div className="flex-1">{
                    typeof filters[key] === 'boolean'
                        ? filters[key] ? t('common.form-label.true') : t('common.form-label.false')
                        : filters[key]
                }</div>
            </div>)
        }
        return render
    }

    return (
        <div className="flex h-full flex-col">

            <div className={" w-1/4 pb-8 text-xs"}>
                {renderFilters(filters)}
            </div>

            <Table
                path="/notificaciones"
                deletePath="/notificaciones"
                query={getQueryString(filters)}
                Form={NotificacionForm}
                // onShow={handleItemClick}
                withPagination
                headers={[
                    {
                        key: 'id',
                        label: 'notificaciones.table.numero'
                    },
                    {
                        key: 'tipo_notificacion',
                        label: 'notificaciones.table.tipo_notificacion'
                    },
                    {
                        key: 'fecha',
                        label: 'notificaciones.table.fecha',
                        type: 'datetime'
                    },
                    {
                        key: 'is_privativa',
                        label: 'notificaciones.table.is_privativa',
                        type: 'boolean'
                    },
                    {
                        key: 'cliente',
                        label: 'notificaciones.table.cliente'
                    },
                    {
                        key: 'instalacion',
                        label: 'notificaciones.table.instalacion'
                    },
                    {
                        key: 'name',
                        label: 'notificaciones.table.nombre'
                    },
                    {
                        key: 'observaciones',
                        label: 'notificaciones.table.observaciones',
                        type: 'longtext'
                    },

                ]}
            />
        </div>
    );
};
