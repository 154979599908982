import React, {useEffect, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';

import {Input} from '../../elements/input';
import {useTranslation} from 'react-i18next';
import {useHttpClient} from '../../shared/http-client';
import {Button} from "../../elements/button";
import {FieldError} from "../../elements/field-error/field-error";
import {StoreKeys, useStore} from "../../shared/store";
import {useNavigate} from "react-router-dom";
import {CogIcon} from "@heroicons/react/outline";

// construccion del objecto yup de validacion del cuestionario
let obligado = {
    name: Yup.string().required('obligatorio'),
    email: Yup.string().email('Introducir email válido').required('obligatorio'),
    password: Yup.string().required('obligatorio').matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "La contraseña ha de contener al menos 8 caracteres, 1 letra mayúscula, 1 letra minúscula y 1 número."
    ),
    schema_name: Yup.string().required('obligatorio').matches(
        /^[a-z][a-z0-9]*$/,
        "solo letras minusculas y numeros"
    ),
};

// yup validate field contains only letters and numbers


const validacion = Yup.object().shape(obligado);

const initialValues = {
    name: '',
    email: '',
    password: '',
    schema_name: '',
};

export const Registro = () => {
        const {t} = useTranslation();
        const navigate = useNavigate();
        const {api, state} = useHttpClient();
        const {set, remove} = useStore();

        const [available, setAvailable] = useState<any | null>(null);
        const [data, setData] = useState<any | null>(initialValues);

        useEffect(() => {
            remove();
        }, []);

        const beforeSubmit = (values: any | null) => {
            let after = {...values};

            return after;
        };

        const onSubmit = async (values: any | null) => {
            //
            const enviaremos = beforeSubmit(values);
            console.log('enviaremos', enviaremos);
            setData(enviaremos);
            api('/plannsat/clientes/', 'POST', {
                name: enviaremos.name,
                email: enviaremos.email,
                schema_name: enviaremos.schema_name,
            });
        };

        useEffect(() => {
                if (state.data) {
                    if (state.path.includes('plannsat/clientes')) {
                        if (state.path.includes('is_available')) {
                            setAvailable(state.data.available);
                        } else if (state.path.includes('plannsat/clientes')) {
                            set(StoreKeys.User, state.data);
                            const userData = {...data}
                            api('/users/', 'POST', {
                                email: userData.email,
                                username: userData.email,
                                password: userData.password,
                                domain: state.data.id,
                                rol: "superadmin"
                            });
                        }
                    }
                    if (state.path.includes('users')) {
                        navigate('/registro/success');
                    }

                }

                if (state.error) {
                    toast.error(t("common.error.tenant-exists"));
                }

            }, [state]
        );

        const handleComprueba = (name: string) => {
            if (name && name !== "") {
                api(`/plannsat/clientes/is_available/?domain=${name}`, 'GET');
            } else {
                setAvailable(null);
            }
        }

        return (
            <div className="min-h-full flex flex-col justify-center py-12 px-2 sm:px-6 lg:px-8 bg-[#ebefff] w-full">
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-2xl">
                    <div className="space-y-8">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                            <img
                                className="h-32"
                                src="/logo.svg"
                                alt="Workflow"
                            />
                        </div>

                        <div className="sm:w-full sm:max-w-full">
                            <h3
                                className={
                                    'font-bold mb-2 text-logo text-2xl'
                                }
                            >
                                {t('registro.text.title')}
                            </h3>
                            <label className="block text-sm font-normal text-gray-700">
                                {t('registro.plan.description')}
                            </label>
                        </div>

                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validationSchema={validacion}
                            validateOnBlur={true}
                            onSubmit={onSubmit}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  setFieldValue,
                                  handleSubmit,
                                  isSubmitting
                              }) => (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    {/*<pre>{JSON.stringify(values, null, 4)}</pre>*/}
                                    {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}

                                    <div className="grid grid-cols-1 gap-1 justify-between items-center w-full">
                                        <div>
                                            <Input
                                                name="name"
                                                label={t('configuracion.form.name')}
                                                type="text"
                                                value={values.name}
                                                onChange={(value) =>
                                                    setFieldValue('name', value.name)
                                                }
                                            />
                                            <FieldError touched={touched} errors={errors} field={"name"}/>
                                        </div>

                                    </div>

                                    <label className="block text-sm font-normal text-gray-700">
                                        {' '}
                                        {t('configuracion.form.choose')}
                                    </label>

                                    {available === true
                                        ? <p className="text-green-500 text-xs italic mt-4">
                                            Disponible
                                            {available ? ` ${values.schema_name}.plannsat.com` : ""}
                                        </p>
                                        : available === false
                                            ? <p className="text-red-500 text-xs italic mt-4">No disponible</p>
                                            : <p className="text-gray-500 text-xs italic"></p>
                                    }

                                    <div className="flex flex-row space-x-2 justify-between items-center w-full">
                                        <div className={"flex flex-col justify-start items-center flex-1"}>
                                            <Input
                                                name="schema_name"
                                                label={t('configuracion.form.schema_name')}
                                                type="text"
                                                value={values.schema_name}
                                                onFocus={() => {
                                                    setFieldValue('schema_name', "")
                                                    setAvailable(null)
                                                }}
                                                onChange={(value) =>
                                                    setFieldValue('schema_name', value.schema_name)
                                                }
                                            />
                                            <FieldError touched={touched} errors={errors} field={"schema_name"}/>
                                            <label className="block text-xs font-medium text-gray-700 mb-4">
                                                {t('registro.user.schema')}
                                            </label>
                                        </div>
                                        <div className={"mb-[64px]"}>
                                            <Button
                                                onClick={() => handleComprueba(values.schema_name)}
                                                label={t('configuracion.button.comprobar')}
                                            />
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1 gap-1 justify-between items-center w-full">

                                        <div>
                                            <Input
                                                name="email"
                                                label={t('user.form.email')}
                                                type="email"
                                                value={values.email}
                                                onChange={(value) =>
                                                    setFieldValue('email', value.email)
                                                }
                                            />
                                            <FieldError touched={touched} errors={errors} field={"email"}/>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1 gap-1 justify-between items-center w-full">

                                        <div>
                                            <Input
                                                name="password"
                                                label={t('user.form.password')}
                                                type="password"
                                                value={values.password}
                                                onChange={(value) =>
                                                    setFieldValue('password', value.password)
                                                }
                                            />
                                            <FieldError touched={touched} errors={errors} field={"password"}/>
                                            <label className="block text-sm font-medium text-gray-700 text-xs mb-4">
                                                {t('registro.user.password')}
                                            </label>

                                        </div>
                                    </div>


                                    <div className="grid grid-cols-1 gap-1 justify-end items-center w-full">
                                        <Button
                                            onClick={handleSubmit}
                                            disabled={isSubmitting}
                                            label={!state.isLoading ? t('configuracion.button.save') : ""}
                                            icon={state.isLoading
                                                ? <CogIcon className={"h-6 w-6 text-gray-400 animate-spin"}/>
                                                : undefined}
                                        />
                                    </div>

                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        );
    }
;
