import {useTranslation} from "react-i18next";
import {ESTADOS} from "../constants";
import {CalendarLegendItem} from "./calendar-legend-item";

export const CalendarLegend = () => {
    const {t} = useTranslation();
    return (
            <div className="flex flex-row space-x-4 border rounded-md p-2">
                <div className={"text-[11px]"}>{t("common.label.estados")}</div>
                {ESTADOS
                    .map((estado: string) => (
                    <CalendarLegendItem estado={estado} key={estado}/>
                ))}
            </div>
    )
}
